import React, { useState, useContext, useEffect } from "react"
import { graphql, Link } from 'gatsby'

import AuthContext from '../context/AuthProvider';
import { getStorage, setStorage, getQuerystringValue } from '../helpers/general'

import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Button from "../components/atoms/Button/Button"
import Dialog from "../components/atoms/Dialog/Dialog"

import * as styles from './sign-in.module.css'

const ContentfulPageLogin = ({ data }) => {
    const auth = useContext(AuthContext);
    const handleLogin = auth && auth.login;
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    const [pageLoaded, setLoaded] = useState(false);
    let redirectTo = '/account';
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(true);
    const [dialogMessage, setDialogMessage] = useState(false);
    const afterAuthCalls = getStorage('_afterAuth');
    let afterAuthCallsObject = {};

    if (afterAuthCalls) {
        afterAuthCallsObject = JSON.parse(afterAuthCalls);
    }

    if (typeof window !== 'undefined' && getQuerystringValue('redirectTo')) {
        redirectTo = getQuerystringValue('redirectTo');
        setStorage('forcedPage', `${window.location.origin}${redirectTo}`, true);
    }

    useEffect(() => {
        if (!pageLoaded && isLoggedIn && typeof window !== 'undefined') {
            window.location = "/account/";
        }
        if (userChecked) {
            setLoaded(true);
        }
    }, [isLoggedIn, pageLoaded, userChecked]);

    const attemptLogin = (e) => {
        e.preventDefault();
        handleLogin(email, password, remember).then(() => {
            // console.log(response);
        }).catch(error => {
            setDialogMessage(error);
        });
    }

    const clearDialog = () => {
        setDialogMessage(false);
    }

    const onChangeCheckbox = (e) => {
        return setRemember(e.target.checked)
    }

    const backgroundImage = data?.loginPage?.blocks[0]?.blocks[0]?.desktopImage.file.url;
    
    if (!isLoggedIn && userChecked) {
        return (
        <Layout>
            <Seo title="Sign in" />
            <div className={`${styles.loginContainer}`} style={!!backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : undefined}>
                <div className={`${styles.loginForm}`}>
                    {'action' in afterAuthCallsObject && afterAuthCallsObject.action === 'saveWishlist' && (
                        <div className={styles.notice}>
                            An account is required to save a wishlist. Please login or sign up for a free account to save your wishlist.
                        </div>
                    )}

                    <div className={styles.loginHeader}>Sign in</div>
                    <form onSubmit={(e) => attemptLogin(e)}>
                        <div className={`formField ${styles.loginFormField}`}>
                            <label className={styles.loginFormFieldLabel} htmlFor="email">Email Address</label>
                            <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className={`formField ${styles.loginFormField}`}>
                            <label className={styles.loginFormFieldLabel} htmlFor="password">Password</label>
                            <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className={`formField ${styles.loginFormActions}`}>
                            <div className={styles.loginFormActionsOp}>
                                <div className={styles.loginRememberMe}>
                                    <input type="radio" className={styles.loginRememberMeRadio} id="remember_me" name="remember_me" value="Remember Me" />
                                    <label className={styles.loginRememberMeLabel} htmlFor="remember_me" action={(e) => onChangeCheckbox(e)} isChecked={remember}>Remember me</label>
                                </div>
                                <Link className={styles.loginForgotPassword} to="/forgotpassword/">Forgot your password?</Link>
                            </div>
                            <Button className={styles.loginSubmitButton} level="primary" type="buttonSubmit">Sign in</Button>
                            <Dialog open={dialogMessage ? true : false} title="Login unsuccessful" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()}>{dialogMessage}</Dialog>
                            <div className={styles.loginSignupLink}>Don’t have an account? <a href="/create-account" target="_self">Create account here</a></div>
                        </div>
                    </form>
                </div>
                <div className={styles.loginBannerWrapper} style={!!backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : undefined}>
                    <div>{data?.loginPage?.pageTitle}</div>
                </div>
            </div>
        </Layout>
        )
    } else {
        return null;
    }
  }
  
export default ContentfulPageLogin;

export const query = graphql`
query ContentfulPageLogin {
    loginPage: contentfulPage(pageName: {eq: "Sign In"}) {
        pageName
        pageTitle
        blocks {
            blocks {
                ... on ContentfulBlockImage {
                    desktopImage {
                        file {
                            url
                        }
                    }
                    mobileImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
}
`
  